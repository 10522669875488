import React, { useCallback, useEffect, useState, useRef } from "react";
import { Divider, Layout } from "antd";

import WorkShopProfile from "./WorkshopProfile";
import { ContactSettings } from "./WorkshopContactSettings";
import { WorkshopLocation } from "./WorkshopLocation";

import WorkshopServices from "../WorkshopServices";
import WorkshopPackages from "./WorkshopPackages";
import { WorkingHours } from "./WorkshopWorkingHours";
import { WorkshopAdditional } from "./WorkshopAdditional";
import { Workshop, WorkHours } from "shared/src/data-models/Workshop";
import { FormInstance } from "antd/lib/form";
import FiscalData from "../WorkshopForm/FiscalData";
interface IBlobImages {
  name: string;
}
interface IProps {
  workshop: Workshop;
  setWorkshop: Function;
  canEdit: boolean;
  form: FormInstance;
  blobListDeleted: IBlobImages[];
  setBlobListDeleted: Function;
  packageList: any[];
  prevSetting: Function;
  saveData: Function;
  /**
   * Use this prop to prevent package and services configuration buttons
   * to be shown and prevent some validations
   * Currently use for new Add Workshop flow
   */
  hidePackagesAndServices?: boolean;
}

let defaultWorkingHours: WorkHours[] = [
  {
    dayCode: 1,
    openTime: "",
    closeTime: "",
    isOpen: false,
  },
  {
    dayCode: 2,
    openTime: "",
    closeTime: "",
    isOpen: false,
  },
  {
    dayCode: 3,
    openTime: "",
    closeTime: "",
    isOpen: false,
  },
  {
    dayCode: 4,
    openTime: "",
    closeTime: "",
    isOpen: false,
  },
  {
    dayCode: 5,
    openTime: "",
    closeTime: "",
    isOpen: false,
  },
  {
    dayCode: 6,
    openTime: "",
    closeTime: "",
    isOpen: false,
  },
  {
    dayCode: 7,
    openTime: "",
    closeTime: "",
    isOpen: false,
  },
];

const MemoizedExxonWorkshop: React.FC<IProps> = ({
  workshop,
  setWorkshop,
  canEdit,
  form,
  blobListDeleted,
  setBlobListDeleted,
  packageList,
  prevSetting,
  saveData,
  hidePackagesAndServices = false,
}) => {
  const warrantyRef = useRef<any>(null);
  const [workshopSettings, setWorkshopSettings] = useState<Workshop>(workshop);
  const [isPackagesOpen, setIsPackagesOpen] = useState<boolean>(false);
  const [isServicesOpen, setIsServicesOpen] = useState<boolean>(false);

  useEffect(() => {
    setWorkshop({
      workingHours: workshopSettings.workingHours,
      services: workshopSettings.services,
    });
  }, [workshopSettings]);

  useEffect(() => {
    let ws: Workshop = { ...workshop };
    if (workshop.workingHours.length == 0) {
      ws.workingHours = defaultWorkingHours;
    }
    setWorkshopSettings(ws);
  }, [workshop]);

  const HandlerWorkingDayToggle = useCallback(
    (index: number, checked: boolean) => {
      setWorkshopSettings((_workshopSettings) => {
        let scheduleDays = [..._workshopSettings.workingHours];
        scheduleDays[index] = {
          ...scheduleDays[index],
          isOpen: checked,
          openTime: checked ? scheduleDays[index].openTime : "",
          closeTime: checked ? scheduleDays[index].closeTime : "",
        };
        return { ..._workshopSettings, workingHours: scheduleDays };
      });
      sessionStorage.setItem("talleres_formchanged", "true"); // setIsTouched
    },
    []
  );

  const HandlerWorkingDayHourChange = useCallback(
    (time: moment.Moment, index: number, name: string) => {
      let timeString = time.toDate().toISOString();
      timeString = timeString.substring(timeString.indexOf("T") + 1);
      setWorkshopSettings((_workshopSettings) => {
        let scheduleDays = [..._workshopSettings.workingHours];
        scheduleDays[index] = { ...scheduleDays[index], [name]: timeString };
        return { ..._workshopSettings, workingHours: scheduleDays };
      });
    },
    []
  );

  const SetSchedule = useCallback((newSchedule: WorkHours[]) => {
    setWorkshopSettings((_workshopSettings) => {
      return { ..._workshopSettings, workingHours: newSchedule };
    });
  }, []);

  const setPhotos = (newPhotos: any) => {
    form.setFields([
      {
        name: ["workshopPhotos"],
        value: newPhotos,
      },
    ]);
  };

  return (
    <Layout>
      <WorkShopProfile
        canEdit={canEdit}
        dsrStatusCode={workshop.dsrStatusCode}
        workshopName={workshop.name}
        workshopSucursalName={workshop.sucursalName}
        workshopId={workshop.id}
        images={workshop.workshopPhotos}
        setWorkshop={setWorkshop}
        blobListDeleted={blobListDeleted}
        setBlobListDeleted={setBlobListDeleted}
        form={form}
        prevSetting={prevSetting}
        setIsPackagesOpen={setIsPackagesOpen}
        setIsServicesOpen={setIsServicesOpen}
        hidePackagesAndServices={hidePackagesAndServices}
        setPhotos={setPhotos}
        packageList={packageList}
        warrantyRef={warrantyRef}
      />

      <ContactSettings canEdit={canEdit} />
      <Divider />

      <FiscalData />
      <Divider />

      <WorkshopLocation
        location={workshopSettings.location}
        setWorkshop={setWorkshop}
        canEdit={canEdit}
      />
      <Divider />

      <WorkingHours
        schedule={workshopSettings.workingHours}
        setSchedule={SetSchedule}
        onChange={HandlerWorkingDayHourChange}
        toggleDays={HandlerWorkingDayToggle}
        form={form}
        canEdit={canEdit}
      />
      <Divider />

      <WorkshopAdditional form={form} canEdit={canEdit} />
      <Divider />

      {/* 
      <WorkshopPackages
        packageList={packageList}
        isOpen={isPackagesOpen}
        setIsOpen={setIsPackagesOpen}
        form={form}
        saveData={saveData}
        warrantySeeMore={() => {
          setIsPackagesOpen(false);

          if (warrantyRef?.current) {
            warrantyRef.current.scrollIntoView({ behavior: "smooth" });
          }
        }}
      />

      <WorkshopServices
        isOpen={isServicesOpen}
        setIsOpen={setIsServicesOpen}
        form={form}
        saveData={saveData}
        workshopId={workshop.id}
        warrantySeeMore={() => {
          setIsServicesOpen(false);
          if (warrantyRef?.current) {
            warrantyRef.current.scrollIntoView({ behavior: "smooth" });
          }
        }}
      /> 
      */}
    </Layout>
  );
};

export const ExxonWorkshop = React.memo(MemoizedExxonWorkshop);
