import React, { useState, useEffect } from "react";
import { CkIcon } from "../../../../../CkUI";

import "./styles.css";

interface IProps {
  manualLabor: {
    enable: boolean;
    timeRange: string;
    time: string;
    km: string;
  };
  parts: {
    enable: boolean;
    timeRange: string;
    time: string;
    km: string;
  };
  onSeeMore: Function;
}

const MemoizedWarrantyPreview: React.FC<IProps> = ({
  manualLabor,
  parts,
  onSeeMore,
}) => {
  const [manualLaborData, setManualLaborData] = useState<any>(null);
  const [partsData, setPartsData] = useState<any>(null);
  const numberFormatter = new Intl.NumberFormat("en-US");

  useEffect(() => {
    const manualLaborTemp = {
      enable: false,
      timeEnable: false,
      kmEnable: false,
    };
    const partsTemp = {
      enable: false,
      timeEnable: false,
      kmEnable: false,
    };
    if (manualLabor) {
      try {
        manualLaborTemp.enable = manualLabor.enable;
        if (manualLabor.time) {
          const intValue = parseInt(manualLabor.time);
          manualLaborTemp.timeEnable = intValue > 0;
        }
        if (manualLabor.km) {
          const intValue = parseInt(manualLabor.km);
          manualLaborTemp.kmEnable = intValue > 0;
        }
      } catch (e) {
        console.warn("Error preview manual labor");
      }
    }
    if (parts) {
      try {
        partsTemp.enable = parts.enable;
        if (parts.time) {
          const intValue = parseInt(parts.time);
          partsTemp.timeEnable = intValue > 0;
        }
        if (parts.km) {
          const intValue = parseInt(parts.km);
          partsTemp.kmEnable = intValue > 0;
        }
      } catch (e) {
        console.warn("Error preview parts");
      }
    }
    setManualLaborData(manualLaborTemp);
    setPartsData(partsTemp);
  }, [parts, manualLabor]);

  const formatTime = (time: string, timeRange: string) => {
    let unit = "";
    if (parseInt(time) > 1) {
      unit = timeRange === "month" ? "meses" : "días";
    } else {
      unit = timeRange === "month" ? "mes" : "día";
    }
    return `${time} ${unit}`;
  };

  const formatKm = (km: string) => {
    if (km === "" || !km) {
      return "";
    }

    try {
      const intValue = parseInt(km);
      return numberFormatter.format(intValue);
    } catch (e) {
      return km;
    }
  };

  return (
    <div className="warranty-preview-container">
      <div className="preview-header">
        <div className="preview-title">
          <CkIcon name="certified-check-shield" />
          Garantía
        </div>
        <div
          className="preview-see-more"
          onClick={() => {
            if (onSeeMore) {
              onSeeMore();
            }
          }}
        >
          Ver más
          <CkIcon name="caret-right" />
        </div>
      </div>
      <div className="preview-body">
        {manualLaborData?.enable && (
          <div className="preview-section">
            Mano de obra
            <div className="preview-content">
              {manualLaborData?.timeEnable && (
                <div className="preview-content-card">
                  Tiempo
                  <div className="preview-content-info">
                    {formatTime(manualLabor.time, manualLabor.timeRange)}
                  </div>
                </div>
              )}
              {manualLaborData?.timeEnable && manualLaborData?.kmEnable && (
                <div className="o">o</div>
              )}
              {manualLaborData?.kmEnable && (
                <div className="preview-content-card">
                  Kilometraje
                  <div className="preview-content-info">{`${formatKm(
                    manualLabor.km
                  )} km`}</div>
                </div>
              )}
            </div>
          </div>
        )}
        {partsData?.enable && (
          <div className="preview-section">
            Refacciones
            <div className="preview-content">
              {partsData?.timeEnable && (
                <div className="preview-content-card">
                  Tiempo
                  <div className="preview-content-info">
                    {formatTime(parts.time, parts.timeRange)}
                  </div>
                </div>
              )}
              {partsData?.timeEnable && partsData?.kmEnable && (
                <div className="o">o</div>
              )}
              {partsData?.kmEnable && (
                <div className="preview-content-card">
                  Kilometraje
                  <div className="preview-content-info">{`${formatKm(
                    parts.km
                  )} km`}</div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="preview-warning">
        En caso de aplicarse, se tomará el parámetro que se cumpla primero.
      </div>
    </div>
  );
};

export const WarrantyPreview = React.memo(MemoizedWarrantyPreview);
