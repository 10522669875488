import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Divider,
  TimePicker,
  Switch,
  Form,
  Radio,
} from "antd";

import moment from "moment";
import { WorkHours } from "shared/src/data-models/Workshop";
import { Day } from "shared/src/data-models/Catalogs";
import { fetchDays } from "shared";
import { useSelector, useDispatch } from "react-redux";
import { getDays } from "../../../../stores/selectors";
import { FormInstance } from "antd/lib/form";
import { useWindowSize } from "../../../Utilities/WindowSize";
import { CkMessage } from "../../../../CkUI";
import "./styles.css";
import { gtmSetId } from "../../../Utilities";

interface IProps {
  schedule: Array<WorkHours>;
  setSchedule: Function;
  toggleDays: Function;
  onChange: Function;
  canEdit: boolean;
  form: FormInstance;
}

const MemoizedWorkingHours: React.FC<IProps> = ({
  schedule,
  setSchedule,
  onChange,
  toggleDays,
  canEdit,
  form,
}) => {
  const windowSize = useWindowSize();
  const format = "HH:mmZ";
  const [useSameHour, setUseSameHour] = useState(false);

  const [useSameHourMondayToFriday, setUseSameHourMondayToFriday] =
    useState(false);

  const days: Day[] = useSelector(getDays);

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchDays("ES-MX"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (schedule.length !== 7)
      setSchedule(
        days.map((day) => {
          return {
            dayCode: day.dayCode,
            openTime: "",
            closeTime: "",
            isOpen: false,
          };
        })
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form.setFieldValue("UseSameTimeEveryDay", useSameHour);
  }, [useSameHour]);

  useEffect(() => {
    form.setFieldValue("UseSameTimeMondayToFriday", useSameHourMondayToFriday);
  }, [useSameHourMondayToFriday]);

  const GetWorkingDayByDayId = (dayCode: number): WorkHours => {
    let workingDay = schedule.find((workingDay) => {
      return workingDay.dayCode === dayCode;
    });

    return workingDay
      ? workingDay
      : { dayCode: -1, openTime: "", closeTime: "", isOpen: false };
  };

  const verifyClosingHour = (time: moment.Moment | null, index: number) => {
    let workingFormHours = form.getFieldValue("workingHours");

    let init = moment(workingFormHours[index].openTime, "HH:mmZ");
    if (time?.isBefore(init, "day")) {
      time.add(1, "day");
    } else if (time?.isAfter(init, "day")) {
      time.subtract(1, "day");
    }

    if (init >= time!) {
      CkMessage({
        type: "error",
        text: "El horario de cierre debe ser mayor al horario de apertura."
      });

      return;
    }
    onChange(time, index, "closeTime");
  };

  const verifyOpeningHour = (time: moment.Moment | null, index: number) => {
    let workingFormHours = form.getFieldValue("workingHours");

    let closingHour = moment(workingFormHours[index].closeTime, "HH:mmZ");
    if (time?.isBefore(closingHour, "day")) {
      time.add(1, "day");
    } else if (time?.isAfter(closingHour, "day")) {
      time.subtract(1, "day");
    }

    if (closingHour < time!) {
      CkMessage({
        type: "error",
        text: "El horario apertura debe ser menor al horario de cierre."
      });
      return;
    }
    onChange(time, index, "openTime");
  };

  return (
    <Form
      form={form}
      className="no-padding"
      style={{ width: "100%" }}
    >
      <div className="working-hours-container">
        <div
          className="form-title"
          style={{ marginTop: windowSize.width > 992 ? "0px" : "23px" }}
        >
          Horario de trabajo
        </div>
        <Row id="workshop-schedule-form" gutter={[24, 8]}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "10px",
            }}
          >
            <Form.Item name="UseSameTimeEveryDay">
              <Radio
                className="--custom"
                id="ACCTSETUP030"
                checked={useSameHour}
                onChange={(e: any) => {
                  setUseSameHour(e.target.checked);
                  if (e.target.checked)
                    setUseSameHourMondayToFriday(!e.target.checked);
                }}
                onClick={(ev) => {
                  gtmSetId(ev.currentTarget);
                  if (useSameHour) setUseSameHour(false);
                }}
                disabled={!canEdit}
              >
                Usar el mismo horario para todos los días
              </Radio>
            </Form.Item>
            <Form.Item name="UseSameTimeMondayToFriday">
              <Radio
                className="--custom"
                id="ACCTSETUP031"
                checked={useSameHourMondayToFriday}
                onChange={(e: any) => {
                  setUseSameHourMondayToFriday(e.target.checked);
                  if (e.target.checked) setUseSameHour(!e.target.checked);
                }}
                onClick={(ev) => {
                  gtmSetId(ev.currentTarget);
                  if (useSameHourMondayToFriday)
                    setUseSameHourMondayToFriday(false);
                }}
                disabled={!canEdit}
              >
                Usar el mismo horario para lunes a viernes
              </Radio>
            </Form.Item>
          </div>

          <Col xs={24}>
            <Form.Item
              name="workingHours"
              hidden={useSameHour || useSameHourMondayToFriday}
            >
              {days.map((day, index) => (
                <div key={day.dayCode}>
                  <Divider style={{ margin: "10px 0" }} />
                  <Row gutter={[16, 8]} style={{ alignItems: "center" }}>
                    <Col
                      sm={2}
                      xs={12}
                      flex="auto"
                      order={windowSize.width >= 576 ? 1 : 2}
                    >
                      <div style={{ display: "flex", gap: "10px" }}>
                        <Switch
                          checked={GetWorkingDayByDayId(day.dayCode).isOpen}
                          onClick={(checked) => {
                            toggleDays(index, checked);
                          }}
                          disabled={!canEdit}
                        />
                        <p
                          style={{
                            margin: "0",
                            fontFamily: "EMprint-regular",
                            fontSize: "16px",
                          }}
                        >
                          {day.dayName}
                        </p>
                      </div>
                    </Col>
                    <Col sm={16} xs={24} order={3}>
                      <Row gutter={[16, 16]}>
                        {windowSize.width >= 576 && <Col flex="auto"></Col>}
                        <Col sm={8} xs={12}>
                          <TimePicker
                            inputReadOnly
                            minuteStep={30}
                            showNow={false}
                            style={{ width: "100%" }}
                            disabled={
                              !GetWorkingDayByDayId(day.dayCode).isOpen ||
                              !canEdit
                            }
                            placeholder="Inicio:"
                            value={
                              GetWorkingDayByDayId(day.dayCode).openTime === ""
                                ? null
                                : moment(
                                    GetWorkingDayByDayId(day.dayCode).openTime,
                                    format
                                  )
                            }
                            format={"HH:mm"}
                            onChange={(time: any) =>
                              verifyOpeningHour(time, index)
                            }
                            // popupStyle={{ width: "100%" }}
                            // className={window.screen.width > 992 ? "" : "mobile-hour"}
                          />
                        </Col>
                        <Col sm={8} xs={12}>
                          <TimePicker
                            inputReadOnly
                            minuteStep={30}
                            showNow={false}
                            style={{ width: "100%" }}
                            placeholder="Fin:"
                            disabled={
                              !GetWorkingDayByDayId(day.dayCode).isOpen ||
                              !canEdit
                            }
                            value={
                              GetWorkingDayByDayId(day.dayCode).closeTime === ""
                                ? null
                                : moment(
                                    GetWorkingDayByDayId(day.dayCode).closeTime,
                                    format
                                  )
                            }
                            format={"HH:mm"}
                            onChange={(time: any) =>
                              verifyClosingHour(time, index)
                            }
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              ))}
            </Form.Item>

            <Form.Item name="everyDayHours" hidden={!useSameHour}>
              <Divider style={{ margin: 0 }} />
              <Row
                gutter={[16, 8]}
                style={{ alignItems: "center", marginTop: 10 }}
              >
                <Col md={14} xs={24}>
                  <p
                    style={{
                      margin: "0",
                      fontFamily: "EMprint-regular",
                      fontSize: "16px",
                    }}
                  >
                    Todos los días
                  </p>
                </Col>
                <Col md={6} xs={12}>
                  <Form.Item
                    style={{ margin: 0 }}
                    name={["everyDayHours", "startingTime"]}
                    rules={[
                      () => ({
                        validator(_: any, value: any) {
                          if ((value && useSameHour) || !useSameHour) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject("Este campo es obligatorio");
                          }
                        },
                      }),
                      () => ({
                        validator(_: any, value) {
                          if (!useSameHour) {
                            return Promise.resolve();
                          }
                          let closingTime = form.getFieldValue([
                            "everyDayHours",
                            "endingTime",
                          ]);
                          if (closingTime) {
                            if (value >= closingTime) {
                              return Promise.reject(
                                "La hora de apertura debe ser menor a la de cierre"
                              );
                            }
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <TimePicker
                      inputReadOnly
                      minuteStep={30}
                      showNow={false}
                      style={{ width: "90px" }}
                      placeholder="Inicio:"
                      format={"HH:mm"}
                      disabled={!canEdit}
                    />
                  </Form.Item>
                </Col>
                <Col md={4} xs={12}>
                  <Form.Item
                    style={{ margin: 0 }}
                    name={["everyDayHours", "endingTime"]}
                    rules={[
                      () => ({
                        validator(_: any, value: any) {
                          if ((value && useSameHour) || !useSameHour) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject("Este campo es obligatorio");
                          }
                        },
                      }),
                      () => ({
                        validator(_: any, value) {
                          if (!useSameHour) {
                            return Promise.resolve();
                          }
                          let startingTime = form.getFieldValue([
                            "everyDayHours",
                            "startingTime",
                          ]);
                          if (startingTime) {
                            if (value <= startingTime) {
                              return Promise.reject(
                                "La hora de cierre debe ser mayor a la de apertura"
                              );
                            }
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <TimePicker
                      inputReadOnly
                      minuteStep={30}
                      showNow={false}
                      style={{ width: "90px" }}
                      placeholder="Fin:"
                      format={"HH:mm"}
                      disabled={!canEdit}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>

            <Form.Item
              name="mondayToFridayHours"
              hidden={!useSameHourMondayToFriday}
            >
              <Divider style={{ margin: 0 }} />
              <Row
                gutter={[16, 8]}
                style={{
                  alignItems: "center",
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                <Col md={16} xs={24}>
                  <p
                    style={{
                      margin: "0",
                      fontFamily: "EMprint-regular",
                      fontSize: "16px",
                    }}
                  >
                    Lunes a viernes
                  </p>
                </Col>
                <Col md={4} xs={12}>
                  <Form.Item
                    style={{ margin: 0 }}
                    name={["mondayToFridayDayHours", "startingTime"]}
                    rules={[
                      () => ({
                        validator(_: any, value: any) {
                          if (
                            (value && useSameHourMondayToFriday) ||
                            !useSameHourMondayToFriday
                          ) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject("Este campo es obligatorio");
                          }
                        },
                      }),
                      () => ({
                        validator(_: any, value) {
                          if (!useSameHourMondayToFriday) {
                            return Promise.resolve();
                          }
                          let closingTime = form.getFieldValue([
                            "mondayToFridayDayHours",
                            "endingTime",
                          ]);
                          if (closingTime) {
                            if (value >= closingTime) {
                              return Promise.reject(
                                "La hora de apertura debe ser menor a la de cierre"
                              );
                            }
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <TimePicker
                      inputReadOnly
                      minuteStep={30}
                      showNow={false}
                      style={{ width: "100%" }}
                      placeholder="Inicio:"
                      format={"HH:mm"}
                      disabled={!canEdit}
                    />
                  </Form.Item>
                </Col>
                <Col md={4} xs={12}>
                  <Form.Item
                    style={{ margin: 0 }}
                    name={["mondayToFridayDayHours", "endingTime"]}
                    rules={[
                      () => ({
                        validator(_: any, value: any) {
                          if (
                            (value && useSameHourMondayToFriday) ||
                            !useSameHourMondayToFriday
                          ) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject("Este campo es obligatorio");
                          }
                        },
                      }),
                      () => ({
                        validator(_: any, value) {
                          if (!useSameHourMondayToFriday) {
                            return Promise.resolve();
                          }
                          let startingTime = form.getFieldValue([
                            "mondayToFridayDayHours",
                            "startingTime",
                          ]);
                          if (startingTime) {
                            if (value <= startingTime) {
                              return Promise.reject(
                                "La hora de cierre debe ser mayor a la de apertura"
                              );
                            }
                          }
                          return Promise.resolve();
                        },
                      }),
                    ]}
                  >
                    <TimePicker
                      inputReadOnly
                      minuteStep={30}
                      showNow={false}
                      style={{ width: "100%" }}
                      placeholder="Fin:"
                      format={"HH:mm"}
                      disabled={!canEdit}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                name="workingHourssaturdaysunday"
                style={{ marginBottom: 0 }}
              >
                {days.map(
                  (day, index) =>
                    (day.dayCode === 6 || day.dayCode === 7) && (
                      <div key={day.dayCode}>
                        <Divider style={{ margin: 0 }} />
                        <Row
                          gutter={[16, 8]}
                          style={{
                            alignItems: "center",
                            marginTop: 10,
                            marginBottom: 10,
                          }}
                        >
                          <Col md={16} xs={24} flex="auto">
                            <div style={{ display: "flex", gap: "10px" }}>
                              <Switch
                                checked={
                                  GetWorkingDayByDayId(day.dayCode).isOpen
                                }
                                onClick={(checked) => {
                                  toggleDays(index, checked);
                                }}
                                disabled={!canEdit}
                              />
                              <p
                                style={{
                                  margin: "0",
                                  fontFamily: "EMprint-regular",
                                  fontSize: "16px",
                                }}
                              >
                                {day.dayName}
                              </p>
                            </div>
                          </Col>
                          <Col md={4} xs={12}>
                            <TimePicker
                              inputReadOnly
                              minuteStep={30}
                              showNow={false}
                              style={{ width: "100%" }}
                              disabled={
                                !GetWorkingDayByDayId(day.dayCode).isOpen ||
                                !canEdit
                              }
                              placeholder="Inicio:"
                              value={
                                GetWorkingDayByDayId(day.dayCode).openTime ===
                                ""
                                  ? null
                                  : moment(
                                      GetWorkingDayByDayId(day.dayCode)
                                        .openTime,
                                      format
                                    )
                              }
                              format={"HH:mm"}
                              onChange={(time: any) =>
                                verifyOpeningHour(time, index)
                              }
                            />
                          </Col>
                          <Col md={4} xs={12}>
                            <TimePicker
                              inputReadOnly
                              minuteStep={30}
                              showNow={false}
                              style={{ width: "100%" }}
                              placeholder="Fin:"
                              disabled={
                                !GetWorkingDayByDayId(day.dayCode).isOpen ||
                                !canEdit
                              }
                              value={
                                GetWorkingDayByDayId(day.dayCode).closeTime ===
                                ""
                                  ? null
                                  : moment(
                                      GetWorkingDayByDayId(day.dayCode)
                                        .closeTime,
                                      format
                                    )
                              }
                              format={"HH:mm"}
                              onChange={(time: any) =>
                                verifyClosingHour(time, index)
                              }
                            />
                          </Col>
                        </Row>
                      </div>
                    )
                )}
              </Form.Item>
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export const WorkingHours = React.memo(MemoizedWorkingHours);
