import React, {
  useState,
  useEffect,
  useCallback,
  Fragment,
  memo,
  useRef,
} from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Input,
  Select,
  Image,
  Popover,
  Tooltip,
  Divider,
  InputNumber,
} from "antd";
import { MoreOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import Dragger from "antd/lib/upload/Dragger";
import { useSelector } from "react-redux";
import { getSasToken, getWorkshopServices } from "../../../../stores/selectors";
import { BlobServiceClient } from "@azure/storage-blob";
import { WorkshopImage } from "shared/src/data-models/Workshop";
import ShareIcon from "../../../../assets/Images/share_icon.svg";
import * as CatalogAPI from "shared/src/services/catalogsAPI";
import "./styles.css";
import { FormInstance } from "antd/es/form/Form";
import { VehicleBrand } from "shared";
import Compressor from "compressorjs";
import { WorkshopAPI } from "../../../Services/axios";
import PopoverPrevious from "../../../../assets/SvgIcons/PopoverPrevious";
import PopoverNext from "../../../../assets/SvgIcons/PopoverNext";
import PopoverDelete from "../../../../assets/SvgIcons/PopoverDelete";
import { CkButton, CkIcon, CkMessage } from "../../../../CkUI";
import { gtmSetId, useIsMobileScreen, useWindowSize } from "../../../Utilities";
import CkEmptyImage from "../../../../CkUI/components/molecules/CkEmptyImage";
import { WorkshopWarranty } from "../WorkshopWarranty";
import AddServicesButton from "../../WorkshopServices/AddServicesButton";
import ServicesResume from "../../WorkshopServices/ServicesResume";


const { Option } = Select;

interface IBlobImages {
  name: string;
}

interface ICount {
  count: number;
}

interface IProps {
  canEdit: boolean;
  images: WorkshopImage[];
  dsrStatusCode: string | undefined;
  workshopName: string;
  workshopSucursalName?: string;
  workshopId?: string;
  setWorkshop: Function;
  blobListDeleted: IBlobImages[];
  setBlobListDeleted: Function;
  form: FormInstance;
  prevSetting: Function;
  setIsPackagesOpen: Function;
  setIsServicesOpen: Function;
  /**
   * Use this prop to prevent package and services configuration buttons
   * to be shown and prevent some validations
   * Currently use for new Add Workshop flow
   */
  hidePackagesAndServices?: boolean;
  setPhotos: Function;
  packageList: any[];
  warrantyRef: any;
}

const account = process.env.REACT_APP_BLOB_ACCOUNT;
const containerName = process.env.REACT_APP_IMAGES_CONTAINER as string;
let blobService: any = null;
let containerClient: any = null;

const ImgComponent: React.FC<{
  photoUrl: string;
  imgIndex: number;
  reorganizeImages: Function;
  imageList: any;
  deleteImage: Function;
  sasToken: string;
}> = ({
  photoUrl,
  imgIndex,
  reorganizeImages,
  imageList,
  deleteImage,
  sasToken,
}) => {
  const [showPopover, setShowPopover] = useState<boolean>(false);

  const handleVisibility = (visibility: boolean) => setShowPopover(visibility);

  const PopoverContent = (
    <div className="pop-over-container">
      {imgIndex > 1 && (
        <div
          className="label"
          style={{ color: "#2F66F2" }}
          onClick={() => {
            reorganizeImages(imgIndex, imgIndex - 1);
            setShowPopover(false);
          }}
        >
          <PopoverPrevious />
          Mover antes
        </div>
      )}
      {imgIndex > 0 && imgIndex !== imageList.length - 1 && (
        <div
          className="label"
          style={{ color: "#2F66F2" }}
          onClick={() => {
            reorganizeImages(imgIndex, imgIndex + 1);
            setShowPopover(false);
          }}
        >
          <PopoverNext />
          Mover después
        </div>
      )}
      <div
        className="label"
        style={{ color: "#F00", gap: 13 }}
        onClick={() => {
          deleteImage(imgIndex);
          setShowPopover(false);
        }}
      >
        <PopoverDelete style={{ marginLeft: 3 }} />
        Borrar
      </div>
    </div>
  );

  return (
    <div className="ws-profile-img-component">
      <Image
        className="img-container ws-profile-image"
        preview={false}
        src={`${photoUrl}?${sasToken}`}
      />
      <Popover
        zIndex={2}
        overlayInnerStyle={{ borderRadius: "10px" }}
        placement="topRight"
        content={PopoverContent}
        trigger="click"
        open={showPopover}
        onOpenChange={handleVisibility}
        getPopupContainer={(triggerNode) => triggerNode || document.body}
      >
        <Button
          onClick={() => setShowPopover((old) => !old)}
          className="icon-btn more-btn"
          icon={<MoreOutlined />}
        />
      </Popover>
    </div>
  );
};

const WorkshopProfile: React.FC<IProps> = ({
  canEdit,
  images,
  dsrStatusCode,
  workshopName,
  workshopSucursalName,
  workshopId,
  setWorkshop,
  blobListDeleted,
  setBlobListDeleted,
  form,
  prevSetting,
  setIsPackagesOpen,
  setIsServicesOpen,
  hidePackagesAndServices = false,
  setPhotos,
  packageList,
  warrantyRef,
}) => {
  const loadingMessageKey = useRef<string>("");
  const sasToken: string = useSelector(getSasToken);
  const workshopServices = useSelector(getWorkshopServices);
  const isMobile = useIsMobileScreen(992);
  const isMobile768 = useIsMobileScreen(768);
  const [workshopVehicleTypes, setWorkshopVehicleTypes] = useState<
    VehicleBrand[]
  >([]);
  const [imageList, setImageList] = useState<Array<WorkshopImage>>(images);
  const [workshopTypeCodes, setWorkshopTypeCodes] = useState<Array<string>>([]);
  const [logoUrl, setLogoUrl] = useState<string>("");
  const [logoIndex, setLogoIndex] = useState<number>(0);
  const [url, setUrl] = useState<string>("");
  const [categoryId, setCategoryId] = useState("Sin definir");

  const [currentServices, setCurrentServices] = useState([]);

  const wsProfileRefCallback = useCallback((node) => {
    if (node) {
      node.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    if (form) {
      const values = form.getFieldsValue();
      if (values && values.typeCodes) {
        setWorkshopTypeCodes([...values.typeCodes]);
      }
      if (values && values.serviceTypes) {
        setCurrentServices(values.serviceTypes);
      }
      if (values && values.workshopCategoryId) {
        setCategoryId(
          values.workshopCategoryId === 0 || values.workshopCategoryId === null
            ? "Por definir"
            : values.workshopCategoryId.toString()
        );
      }
    }
  }, [form]);

  useEffect(() => {
    setImageList(images);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let result = await CatalogAPI.fetchVehicleTypes();
      if (result?.status === 200) {
        const mappedResult = result.data;
        setWorkshopVehicleTypes(mappedResult);
      }
    };

    fetchData();
  }, [workshopId]);

  useEffect(() => {
    let baseURL = process.env.REACT_APP_EC_URL;
    if (baseURL?.slice(-1) !== "/") {
      baseURL = baseURL + "/";
    }
    setUrl(
      `${baseURL}workshop/${workshopName
        .replace(/[^a-zA-Z ]/g, "")
        .replaceAll(" ", "_")}${
        workshopSucursalName && workshopSucursalName.length > 0
          ? "_" +
            workshopSucursalName.replace(/[^a-zA-Z ]/g, "").replaceAll(" ", "_")
          : ""
      }/${workshopId ? workshopId : ""}`
    );
  }, []);

  useEffect(() => {
    if (sasToken) {
      blobService = new BlobServiceClient(`${account}?${sasToken}`);
      containerClient = blobService.getContainerClient(containerName);
    }
  }, [sasToken]);

  useEffect(() => {
    if (imageList && Array.isArray(imageList) && imageList.length > 0) {
      // Force logo to be first always
      const imageLogoIndex = imageList.findIndex(
        (image) => image.description === "LOGO"
      );
      if (imageList[0].description !== "LOGO" && imageLogoIndex !== -1) {
        const imageCopy = JSON.parse(JSON.stringify(imageList[imageLogoIndex]));
        const imageListCopy = JSON.parse(JSON.stringify(imageList));
        imageListCopy.splice(imageLogoIndex, 1);
        imageListCopy.unshift(imageCopy);
        setImageList(imageListCopy);
        setLogoUrl(imageListCopy[0].photoUrl);
        setLogoIndex(0);
      } else if (imageLogoIndex === 0) {
        setLogoUrl(imageList[0].photoUrl);
        setLogoIndex(0);
      }
      setWorkshop({ workshopPhotos: imageList });
    }
  }, [imageList]);

  /**
   *
   * @param file file object
   * @param fileType LOGO | GALLERY
   * @returns
   */
  const beforeUploadProp = (file: any, fileType: "LOGO" | "GALLERY") => {
    let imgType: Object = {
      "image/png": true,
      "image/jpg": true,
      "image/jpeg": true,
    };

    if (!imgType.hasOwnProperty(file.type)) {
      CkMessage({
        type: "error",
        text: `${file.name} no es un formato soportado de imágen.`,
      });
    }

    const isLt2M = file.size > 3120968 ? true : false;

    if (isLt2M) {
      file.flag = true;
      CkMessage({ type: "error", text: `${file.name} supera los 3 MB.` });
      return false;
    }

    if (
      fileType === "GALLERY" &&
      imageList.filter((file) => file.description !== "LOGO").length >= 8
    ) {
      CkMessage({
        type: "error",
        text: "Solo se pueden agregar hasta 8 imágenes y un logo.",
      });
      return false;
    }

    return imgType.hasOwnProperty(file.type);
  };

  const onChangeProp = (info: any) => {
    const { status } = info.file;
    if (status === "uploading") {
      loadingMessageKey.current = CkMessage({
        type: "loading",
        text: `Subiendo imagen ${info.file.name}`,
        ...(loadingMessageKey.current
          ? { messageToDestroy: loadingMessageKey.current }
          : {}),
      });
    } else if (status === "done") {
      CkMessage({
        type: "success",
        text: `${info.file.name} subida exitosamente. Recuerda guardar para salvar los cambios a las imágenes.`,
        ...(loadingMessageKey.current
          ? { messageToDestroy: loadingMessageKey.current }
          : {}),
      });
      if (loadingMessageKey.current) loadingMessageKey.current = "";
    } else if (status === "error") {
      CkMessage({
        type: "error",
        text: `${info.file.name} error al subir imagen.`,
      });
      if (loadingMessageKey.current) loadingMessageKey.current = "";
    }
  };

  const uploadProps = {
    name: "file",
    multiple: false,
    beforeUpload: (file: any) => beforeUploadProp(file, "GALLERY"),
    customRequest: ({ onSuccess, onError, file }: any) => {
      uploadImage(onSuccess, onError, file, "Uploaded on Workshop setup");
    },
    onChange(info: any) {
      onChangeProp(info);
    },
  };

  const uploadPropsLogo = {
    name: "file",
    multiple: false,
    beforeUpload: (file: any) => beforeUploadProp(file, "LOGO"),
    customRequest: ({ onSuccess, onError, file }: any) => {
      uploadImage(onSuccess, onError, file, "LOGO");
    },
    onChange(info: any) {
      onChangeProp(info);
    },
  };

  const deleteImage = async (index: number) => {
    let updatedImageList = [...imageList];
    try {
      let temp = [...blobListDeleted];
      await WorkshopAPI.photosCount(imageList[index].photoUrl!)
        .then((response: any) => {
          let data: ICount = response.data;
          if (data.count === 1) {
            temp.push({
              name: imageList[index].name!,
            });
          }
        })
        .catch((err: any) => {
          console.log(err);
        });

      setBlobListDeleted(temp);
      updatedImageList.splice(index, 1);

      setImageList(updatedImageList);
      CkMessage({
        type: "success",
        text: "Imagen eliminada exitosamente. Recuerda guardar para salvar los cambios a las imágenes.",
      });
    } catch (error) {
      if (error.statusCode && error.statusCode === 404) {
        updatedImageList.splice(index, 1);
        setImageList(updatedImageList);
        return;
      }
      CkMessage({
        type: "error",
        text: "Ha ocurrido un error al eliminar la imagen.",
      });
    }
  };

  const uploadImage = async (
    onSuccess: Function,
    onError: Function,
    file: any,
    description: string
  ) => {
    let date = new Date();
    let fileName = `${date.getTime()}_${file.name}`;
    const blockBlobClient = containerClient.getBlockBlobClient(fileName);
    try {
      let newArray = [...imageList];
      if (description === "LOGO") {
        newArray = newArray.filter((file, index) => {
          if (file.description === "LOGO") {
            deleteImage(index);
          } else {
            return file;
          }
        });
      }
      new Compressor(file, {
        quality: 0.6,
        convertTypes:
          "image/png, image/webp, image/gif, image/tiff, image/apng, image/ico, image/cur, image/ai, image/svg, image/raw, image/jfif",
        convertSize: 2000000,
        async success(result) {
          await blockBlobClient.upload(result, result.size);
          newArray = [
            ...(description === "LOGO"
              ? [
                  {
                    name: fileName,
                    photoUrl: blockBlobClient.url.split("?")[0],
                    description,
                  },
                ]
              : []),
            ...newArray,
            ...(description !== "LOGO"
              ? [
                  {
                    name: fileName,
                    photoUrl: blockBlobClient.url.split("?")[0],
                    description,
                  },
                ]
              : []),
          ];
          setImageList(newArray);
          sessionStorage.setItem("imagesWorkshop", JSON.stringify(newArray));
          onSuccess();
        },
      });
    } catch (error) {
      onError();
    }
  };

  const reorganizeImages = (oldIndex: number, newIndex: number) => {
    const imagesCopy = [...imageList];
    const oldImageCopy = JSON.parse(JSON.stringify(imagesCopy[oldIndex]));
    imagesCopy.splice(oldIndex, 1);
    imagesCopy.splice(newIndex, 0, oldImageCopy);
    setImageList(imagesCopy);
    console.log(imagesCopy);
    setPhotos(imagesCopy);
  };

  const imageListRender = (images: any) => {
    if (!images) return <></>;

    let imgListCopy = [...images];

    // Remove logo from the list
    const imgListSorted = imgListCopy
      .sort((a: any, b: any) => {
        if (a?.description && b?.description)
          return a.description.localeCompare(b.description);
        else return false;
      })
      .slice(1);

    if (imgListSorted.length < 8 && !isMobile) {
      const fill = 8 - imgListSorted.length;
      for (let index = 0; index < fill; index++) {
        imgListSorted.push({ photoUrl: "" });
      }
    }

    return (
      <div
        style={isMobile ? {} : { display: "flex", justifyContent: "center" }}
      >
        {isMobile ? <CkEmptyImage {...uploadProps} /> : undefined}
        <div className="ws-images-flex" style={{ maxWidth: 840 }}>
          {imgListSorted.slice(0, 4).map((item, index) => {
            let imgIndex = imageList.findIndex(
              (originalImage: any) => item.photoUrl === originalImage.photoUrl
            );
            return (
              <div className="ws-image-wrapped">
                {item.photoUrl && item.photoUrl !== "" ? (
                  <ImgComponent
                    key={`img-1-${index}`}
                    photoUrl={item.photoUrl}
                    imgIndex={imgIndex}
                    reorganizeImages={reorganizeImages}
                    imageList={imageList}
                    deleteImage={deleteImage}
                    sasToken={sasToken}
                  />
                ) : (
                  <CkEmptyImage {...uploadProps} />
                )}
              </div>
            );
          })}

          {imgListSorted.length > 4 && (
            <>
              {imgListSorted.slice(4).map((item, index) => {
                let imgIndex = imageList.findIndex(
                  (originalImage: any) =>
                    item.photoUrl === originalImage.photoUrl
                );
                return (
                  <div className="ws-image-wrapped">
                    {item.photoUrl && item.photoUrl !== "" ? (
                      <ImgComponent
                        key={`img-2-${index}`}
                        photoUrl={item.photoUrl}
                        imgIndex={imgIndex}
                        reorganizeImages={reorganizeImages}
                        imageList={imageList}
                        deleteImage={deleteImage}
                        sasToken={sasToken}
                      />
                    ) : (
                      <CkEmptyImage {...uploadProps} />
                    )}
                  </div>
                );
              })}
            </>
          )}
        </div>
      </div>
    );
  };

  const getPackageDisplayList = (pList: any[]) => {
    const summarizedPackages = [];
    pList.map((_package) => {
      if (_package.price === 0) return;
      const foundPackage = summarizedPackages.find(
        (_pack) => _pack.name === _package.servicePackage.servicePackageDesc
      );
      if (foundPackage) {
        const currentCategory =
          _package.servicePackageType.servicePackageTypeDesc;
        foundPackage.categories.push(currentCategory);
      } else {
        summarizedPackages.push({
          name: _package.servicePackage.servicePackageDesc,
          categories: [_package.servicePackageType.servicePackageTypeDesc],
        });
      }
    });

    return (
      <Fragment>
        {summarizedPackages.map((p, index) => {
          return (
            <span>
              {index !== 0 ? ", " : ""}
              {p.name} (
              {p.categories.map(
                (cat: any, index: number) => `${index !== 0 ? ", " : ""}${cat}`
              )}
              ){" "}
            </span>
          );
        })}
      </Fragment>
    );
  };

  const getServiceFromCode = (code: string) => {
    switch (code) {
      case "Moto":
        return "Motocicletas";
      case "LFyS":
        return "Llantas, frenos y suspensión";
      case "BySE":
        return "Eléctrico y baterías";
      case "HyP":
        return "Hojalatería y pintura";
      case "MMG":
        return "Mecánica general";
      case "TyD":
        return "Transmisión y dirección";
    }
  };

  const getServiceDisplayList = (serviceList: string[]) => {
    let res = "";
    if (serviceList && serviceList?.length > 0) {
      serviceList?.map((serv, index) => {
        res += `${index > 0 ? ", " : ""}${getServiceFromCode(serv)}`;
      });
    }
    return <span>{res}</span>;
  };

  return (
    <div ref={wsProfileRefCallback} className="ws-profile-container">
      <Row>
        <div className="form-container">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: "17px",
            }}
          >
            <div className="form-title-ws-profile">Datos del taller</div>
            <div style={{ fontSize: "14px", color: "#AAAAAA" }}>
              * = Obligatorio
            </div>
          </div>
        </div>

        <Col sm={24} md={7} xl={6} style={{ width: "100%" }}>
          <div className="logo-space-container">
            <p
              style={{
                textAlign: "start",
                margin: 0,
                fontWeight: "600",
                fontSize: "16px",
              }}
            >
              *Logo del taller
            </p>
            <div className="logo-container">
              {!!logoUrl && sasToken ? (
                <ImgComponent
                  photoUrl={logoUrl}
                  imgIndex={logoIndex}
                  reorganizeImages={reorganizeImages}
                  imageList={imageList}
                  deleteImage={deleteImage}
                  sasToken={sasToken}
                />
              ) : (
                <CkEmptyImage {...uploadPropsLogo} />
              )}
            </div>
          </div>
        </Col>

        <Col sm={24} md={17} xl={18} style={{ paddingTop: "20px" }}>
          <Row>
            {/*Nombre*/}
            <Col
              md={12}
              sm={24}
              style={{ width: "100%", paddingRight: "20px" }}
            >
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Ingresa el nombre del taller",
                  },
                  {
                    max: 50,
                    message: "No puede excederse de 50 caracteres",
                  },
                ]}
                name="name"
                extra="*Nombre del taller (nombre comercial)"
              >
                <Input
                  style={{ width: "100%" }}
                  disabled={!canEdit}
                  placeholder="Ingresa el nombre del taller"
                />
              </Form.Item>
            </Col>

            {/*Tipo de vehiculo*/}
            <Col
              md={12}
              sm={24}
              style={{ paddingRight: "20px", width: "100%" }}
            >
              <Form.Item
                name="carTypes"
                rules={[
                  {
                    required: true,
                    message: "Ingresa el tipo del vehículo",
                  },
                ]}
                extra="*Tipo de vehículo (selecciona hasta 3)"
              >
                <Select
                  className="select-vehicle-type-edit"
                  style={{ width: "100%" }}
                  mode="multiple"
                  allowClear
                  showSearch
                  placeholder="*Tipo de vehículo"
                  disabled={!canEdit}
                  getPopupContainer={(triggerNode) =>
                    triggerNode || document.body
                  }
                >
                  {workshopVehicleTypes.map((item) => {
                    let itemDescription: string;
                    if (!isMobile768) {
                      itemDescription =
                        item.desc === "VEHICULOS DE CARGA LIGERA"
                          ? "CARGA LIGERA"
                          : item.desc;
                    } else {
                      itemDescription = item.desc;
                    }
                    return (
                      <Option value={item.code} key={item.code}>
                        {`${itemDescription[0]}${itemDescription
                          .toLowerCase()
                          .substring(1)}`}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            {/*ID*/}
            <Col md={12} sm={24} style={{ paddingRight: "20px" }}>
              <Form.Item
                className="workshop-identificator-form-item"
                name="workshopIdentificator"
                extra="ID"
                style={{ marginBottom: "0 !important" }}
              >
                <InputNumber
                  style={{ width: "100%" }}
                  disabled={!canEdit}
                  placeholder="Ingresa un número para identificar al taller: Ej. 01"
                />
              </Form.Item>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: "3px",
                }}
              >
                <CkIcon name="information-round" width={18} height={18} />
                <p
                  style={{
                    fontSize: "12px",
                    color: "#404040",
                    paddingLeft: "3px",
                    margin: 0,
                  }}
                >
                  Este dato interno, visible solo para los usuarios de tu equipo
                  de CarKer, puede ayudarte en la gestión de múltiples talleres.
                </p>
              </div>
            </Col>

            {/*Categoria*/}
            <Col md={12} sm={24} style={{ paddingRight: "20px" }}>
              <Form.Item name="workshopCategoryId" extra="Categoría">
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <p
                    className="simple-lightblue-highlight"
                    style={{ marginRight: "10px" }}
                  >
                    {categoryId}
                  </p>
                  <Tooltip title="La categoría del taller es asignada por CarKer de acuerdo a ciertas características.">
                    <CkIcon name="information-round" />
                  </Tooltip>
                </div>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>

      {/*Acerca de*/}
      <Row>
        <Form.Item
          name="about"
          extra="Acerca de"
          className="workshop-form-about"
          style={{
            width: "100%",
            padding: "0px",
          }}
        >
          <TextArea
            autoSize={{ minRows: 3, maxRows: 3 }}
            placeholder="Escribe tu mensaje, máximo 240 caracteres"
            maxLength={240}
          />
        </Form.Item>
      </Row>

      {/*Ver pagina como cliente*/}
      {dsrStatusCode === "3" && (
        <Row id="workshop-profile-form5">
          <Col sm={24}>
            <div className="client-link-container">
              <a href={url} target="_blank" rel="noopener noreferrer" id="ACCTSETUP020" onClick={(ev) => gtmSetId(ev.currentTarget)}>
                <div className="client-link-text">Ver página como cliente</div>
              </a>
              <Image
                src={ShareIcon}
                alt="share icon"
                preview={false}
                onClick={() => {
                  CkMessage({
                    type: "success",
                    text: "Copiado el link al portapapeles",
                  });
                  navigator.clipboard.writeText(url);
                }}
                style={{ color: "var(--primary-color)" }}
              />
            </div>
          </Col>
        </Row>
      )}
      <Divider />

      <div className="section-container services">
        <h2 className="form-title-ws-profile">Servicios</h2>
        {workshopServices.length === 0 && <p className="cta">¡Agrega tu primer servicio para que tu taller sea visible en CarKer! </p> }
        {workshopServices.length !== 0 && <ServicesResume />}
        <AddServicesButton />
      </div>

      {workshopServices.length > 0 && 
      <div className="section-container warranties">
        <h2 className="form-title-ws-profile">Garantias</h2>

      </div>}

      {/* Legacy packages and services */}
      {/*
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          className="form-title-ws-profile"
          style={{ paddingBottom: "10px" }}
        >
          Servicios
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <CkIcon name="information-round" />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingLeft: "10px ",
            }}
          >
            <p className="zero-margin">
              <span className="bold">Mantenimientos</span>: consisten en
              servicios estandarizados, con un precio definido, para el
              mantenimiento óptimo de un vehículo.
            </p>
            <p className="zero-margin">
              <span className="bold">Reparaciones</span>: son las correcciones
              de una falla o problema específico en el vehículo.
            </p>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <CkButton
            variant={
              packageList.filter((pack) => pack.price > 0).length > 0
                ? "secondary"
                : "primary"
            }
            onClick={() => setIsPackagesOpen(true)}
            style={{ margin: "3px" }}
            id="ACCTSETUP032"
            triggerGTMOnClick
          >
            {packageList.filter((pack) => pack.price > 0).length > 0
              ? "Editar mantenimientos"
              : "+ Agregar mantenimientos"}
          </CkButton>
          <CkButton
            variant={currentServices.length > 0 ? "secondary" : "primary"}
            onClick={() => setIsServicesOpen(true)}
            style={{ margin: "3px" }}
            id="ACCTSETUP033"
            triggerGTMOnClick
          >
            {currentServices.length > 0
              ? "Editar reparaciones"
              : "+ Agregar reparaciones"}
          </CkButton>
        </div>

        <p style={{ fontSize: "14px", color: "#2369BD" }}>
          Servicios con los que cuenta este taller:
        </p>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <p
            style={{
              fontSize: "12px",
              height: "fit-content",
              color: "#2369BD",
              borderRadius: "10px",
            }}
            className="simple-lightblue-highlight bold"
          >
            {packageList.filter((pack) => pack.price > 0).length}
          </p>
          <p style={{ fontSize: "14px", paddingLeft: "5px" }}>
            <span className="bold">Mantenimientos</span>{" "}
            {packageList.length > 0
              ? getPackageDisplayList(packageList)
              : undefined}
          </p>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <p
            style={{
              fontSize: "12px",
              height: "fit-content",
              color: "#2369BD",
              borderRadius: "10px",
            }}
            className="simple-lightblue-highlight bold"
          >
            {currentServices.length}
          </p>
          <p style={{ fontSize: "14px", paddingLeft: "5px" }}>
            <span className="bold">Reparaciones</span>{" "}
            {currentServices.length > 0
              ? getServiceDisplayList(currentServices)
              : undefined}
          </p>
        </div>
      </div>
      <Divider />
      */}

      {/* Legacy warranties */}
      {/* 
      <div style={{ display: isMobile ? "none" : "" }}>
        <WorkshopWarranty
          warrantyRef={warrantyRef}
          form={form}
          packageList={packageList}
          serviceList={form.getFieldsValue().serviceTypes}
        />
        <Divider />
      </div>  
      */}

      {/*Galeria*/}
      <div className="form-container">
        <Row
          id="workshop-profile-form4"
          gutter={[26, 30]}
          style={{ marginBottom: dsrStatusCode !== "3" ? "20px" : 0 }}
        >
          <Col span={24}>
            <div className="ant-form-item-extra">
              <div className="form-title-ws-profile">Galería</div>
              <p
                style={{
                  paddingTop: "15px",
                  fontSize: "16px",
                  fontWeight: 400,
                }}
              >
                <span className="bold">Agregar imágenes del taller</span> (hasta
                8):
              </p>
            </div>
            {imageListRender(imageList)}
          </Col>
        </Row>
      </div>
      <Divider />
    </div>
  );
};

export default memo(WorkshopProfile);
